import { Button, Card, CardContent, CircularProgress, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TextField, Typography } from "@mui/material";
import styles from "app/cards/styles";
import LoaderButton from "app/components/LoaderButton";
import AllBankColumns from "app/components/TransactionTables/TableColumns/AllBankColumns";
import CreditDebitColumns from "app/components/TransactionTables/TableColumns/CreditDebit";
import GCashColumns from "app/components/TransactionTables/TableColumns/GCashColumns";
import GCashQRPHColumns from "app/components/TransactionTables/TableColumns/GCashQRPHColumns";
import ICashColumns from "app/components/TransactionTables/TableColumns/ICashColumns";
import JPTColumns from "app/components/TransactionTables/TableColumns/JPTColumn";
import RBGIColumns from "app/components/TransactionTables/TableColumns/RBGIColumns";
import AllBankRow from "app/components/TransactionTables/TableRows/AllBankRow";
import CreditDebitRow from "app/components/TransactionTables/TableRows/CreditDebitRow";
import GCashQRPHRow from "app/components/TransactionTables/TableRows/GCashQRPHRow";
import GCashRow from "app/components/TransactionTables/TableRows/GCashRow";
import ICashRow from "app/components/TransactionTables/TableRows/ICash";
import JPTRow from "app/components/TransactionTables/TableRows/JPTRow";
import RBGIRow from "app/components/TransactionTables/TableRows/RBGIRow";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Columns = {
    allbank     : AllBankColumns,
    rbgi        : RBGIColumns,
    icash       : ICashColumns,
    'gcash-h5'  : GCashColumns,
    'gcash-qrph': GCashQRPHColumns,
    card        : CreditDebitColumns,
    jpt         : JPTColumns,
}
const Rows = {
    allbank     : AllBankRow,
    rbgi        : RBGIRow,
    icash       : ICashRow,
    'gcash-h5'  : GCashRow,
    'gcash-qrph': GCashQRPHRow,
    card        : CreditDebitRow,
    jpt         : JPTRow,
}

type BankType = 'allbank' | 'rbgi' | 'icash' | 'gcash-h5' | 'card' | 'jpt' | 'gcash-qrph'

interface Props {
    type        : string
    title?      : string
    bank        : BankType
    source     ?: string
    intent     ?: string
    category   ?: string
    client     ?: string
    account    ?: string
    showBalance?: boolean
    showTotal  ?: boolean
}

const TransactionsTable = ({
    showBalance,
    showTotal,
    bank,
    source,
    title,
    intent,
    category,
    type,
    client,
    account
}: Props) => {
    const rowsPerPage = 1000;
    const {id} = useParams();
    const {partner} = useLoggedInRoute();
    const {
        getBalance,
        getTotal,
        getTransactions,
        downloadTransactions
    } = useApi();

    const [transactions, setTransactions] = useState<any[]>([]);
    const [loading, setLoading]           = useState(false);
    const [downloading, setDownloading]   = useState(false);
    const [total, setTotal]               = useState('');
    const [balance, setBalance]           = useState(0);
    const [search, setSearch]             = useState('');
    const [page, setPage]                 = useState(0);
    const [start, setStart]               = useState(moment().format('YYYY-MM-DD'));
    const [end, setEnd]                   = useState(moment().format('YYYY-MM-DD'));
    const [last_key, setLastKey]          = useState<string>('');

    const TableColumns = ({Columns, ...params}: any) => <Columns {...params}/>
    const TableRows    = ({Rows, ...params}: any) => <Rows {...params}/>

    const fetchBalance = () => getBalance({bank, source, type, client, account}).then(res => setBalance(+(res.CurrentBalance || 0)))

    const fetchTotal = async () => {
        let response = await getTotal({
            id, bank, type, start, end, source, intent, category, client, account
        }).then(
            res => {
                if (res.status == 401) {
                    sessionExpired()
                }
                return res.text()
            }
        ).catch(() => sessionExpired())
        setTotal(amountFormat(+response));
    }

    const fetchTransactions = () => {
        getTransactions({id, type, bank, source, intent, start, end, category, client, account}).then(res => {
            if (res.status == 401) {
                sessionExpired()
            }
            return res
        })
        .then(res => res.json())
        .then(response => {
            if (last_key) {
                response.transactions = [...transactions, ...response.transactions];
            }
            setLastKey(response.LastEvaluatedKey);
            setTransactions(response.transactions);
        })
        .catch(() => sessionExpired())
        .finally(() => setLoading(false));
    }

    const download = (file_type: 'xlsx' | 'csv') => {
        setDownloading(true);
        downloadTransactions({id, type, bank, source, intent, start, end, file_type, category, client, account}).then(async res => {
            if (res.status == 401) {
                sessionExpired()
            }
            return {
                filename: decodeURI(res.headers.get('Content-Disposition')?.replace("attachment; filename*=utf-8''", '') || ''),
                blob    : await res.blob()
            }
        })
        .then(({filename, blob}: any) => {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.target = "_blank";
            link.click();
            link.remove();
        })
        .catch(() => sessionExpired())
        .finally(() => setDownloading(false))
    }

    const apply = () => {
        setLoading(true)
        switch (bank) {
            case 'gcash-h5': fetchTotal(); break;
            case 'card'    : fetchTotal(); break;
            default:
                switch (type?.toLowerCase()) {
                    case 'admin':
                        switch (intent) {
                            case 'cash_out': fetchTotal(); break;
                            default: fetchBalance(); break;
                        }
                        break
                    case 'partner':
                        switch (intent) {
                            case 'cash_out': fetchBalance(); break;
                            default: fetchTotal(); break;
                        }
                        break
                }
        }
        fetchTransactions()
    }

    const onChangePage = (_: any, page: number) => {
        if (page*rowsPerPage > transactions.length) {
            if (last_key) {
                fetchTransactions()
            } else {
                return setPage(page-1)
            }
        }
        setPage(page)
    }

    const sessionExpired = () => Swal.fire({
        title: 'Session Expired',
        text: 'Please login again',
        icon: 'warning',
        allowOutsideClick: false
    }).then((value) => {
        if (value.isConfirmed) {
            sessionStorage.clear()
            window.location.reload()
        }
    })
    
    const filter = (i: any) => JSON.stringify(i).toLowerCase().includes(search)

    useEffect(() => partner?.access_token && apply(), [partner?.access_token])

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    spacing      = {2}
                    alignContent = "center"
                    alignItems   = "center"
                >
                    <Grid item xs={4}>
                        <Typography variant="h6" fontWeight="bold" sx={styles.tableTitle}>
                            {title}
                            <LoaderButton variant="contained" onClick={apply} loading={loading} disabled={!!loading} sx={{margin: '0 1rem'}}>
                                REFRESH
                            </LoaderButton>
                        </Typography>
                        {
                            (showBalance || showTotal) &&
                            <Typography variant="h6" sx={styles.tableSubtitle}>
                                {showBalance && <>Balance: {(+balance).toLocaleString('en-US', {style: 'currency', currency: 'PHP'})}</>}
                                {showTotal && <>{(+(total.replaceAll(',', ''))).toLocaleString('en-US', {style: 'currency', currency: 'PHP'})}</>}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container
                            spacing        = {2}
                            alignItems     = "center"
                            justifyContent = "flex-end"
                        >
                            <Grid item>
                                <TextField
                                    id              = "searchField"
                                    label           = "Search"
                                    type            = "text"
                                    value           = {search}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setSearch(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "Start Date"
                                    type            = "date"
                                    value           = {start}
                                    inputProps      = {{max: end}}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setStart(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id              = "date"
                                    label           = "End Date"
                                    type            = "date"
                                    value           = {end}
                                    InputLabelProps = {{shrink: true}}
                                    onChange        = {(e: any) => setEnd(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant  = "contained"
                                    color    = "primary"
                                    size     = "large"
                                    onClick  = {() => partner?.access_token && apply()}
                                    disabled = {!!loading}
                                >
                                    {loading? <CircularProgress size="20px" />: 'APPLY'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader style={{minWidth: '100%'}}>
                        <TableHead>
                            <TableColumns
                                Columns={Columns[bank]}
                                isAdmin={type == 'admin'}
                            />
                        </TableHead>
                        <TableBody>
                            {transactions?.map((item: any) => 
                                <TableRows
                                    Rows={Rows[bank]}
                                    item={item}
                                    isAdmin={type == 'admin'} 
                                    amountFormat={amountFormat} 
                                    computeAmount={computeAmount}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container alignContent="space-between">
                    <Grid item>
                        <Button
                            color   = "primary"
                            variant = "contained"
                            disabled = {!!downloading}
                            style   = {styles.downloadButton}
                            onClick = {() => download('xlsx')}
                        >
                            Download (.xlsx)
                        </Button>
                        <Button
                            color   = "primary"
                            variant = "contained"
                            disabled = {!!downloading}
                            style   = {styles.downloadButton}
                            onClick = {() => download('csv')}
                        >
                            Download (.csv)
                        </Button>
                    </Grid>
                    <Grid item>
                        <TablePagination
                            component          = "div"
                            align              = "right"
                            count              = {transactions?.filter(filter)?.length || 0}
                            onPageChange       = {onChangePage}
                            page               = {page}
                            rowsPerPage        = {rowsPerPage}
                            rowsPerPageOptions = {[]}
                            slotProps          = {{actions: {nextButton: { disabled: false }}}}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>
}

const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})
const computeAmount = (amount: number, fee_percentage: number) => amount - amount * fee_percentage/100

export default TransactionsTable;