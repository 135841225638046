import { Box, Button, Card, CardContent, CircularProgress, Grid, Paper, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import styles from "app/cards/styles";
import LoaderButton from "app/components/LoaderButton";
import AllBankColumns from "app/components/TransactionTables/TableColumns/AllBankColumns";
import CreditDebitColumns from "app/components/TransactionTables/TableColumns/CreditDebit";
import GCashColumns from "app/components/TransactionTables/TableColumns/GCashColumns";
import GCashQRPHColumns from "app/components/TransactionTables/TableColumns/GCashQRPHColumns";
import ICashColumns from "app/components/TransactionTables/TableColumns/ICashColumns";
import JPTColumns from "app/components/TransactionTables/TableColumns/JPTColumn";
import RBGIColumns from "app/components/TransactionTables/TableColumns/RBGIColumns";
import AllBankRow from "app/components/TransactionTables/TableRows/AllBankRow";
import CreditDebitRow from "app/components/TransactionTables/TableRows/CreditDebitRow";
import GCashQRPHRow from "app/components/TransactionTables/TableRows/GCashQRPHRow";
import GCashRow from "app/components/TransactionTables/TableRows/GCashRow";
import ICashRow from "app/components/TransactionTables/TableRows/ICash";
import JPTRow from "app/components/TransactionTables/TableRows/JPTRow";
import RBGIRow from "app/components/TransactionTables/TableRows/RBGIRow";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import useApi from "app/pages/Dashboard/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const Columns = {
    allbank     : AllBankColumns,
    rbgi        : RBGIColumns,
    icash       : ICashColumns,
    'gcash-h5'  : GCashColumns,
    'gcash-qrph': GCashQRPHColumns,
    card        : CreditDebitColumns,
    jpt         : JPTColumns,
}
const Rows = {
    allbank     : AllBankRow,
    rbgi        : RBGIRow,
    icash       : ICashRow,
    'gcash-h5'  : GCashRow,
    'gcash-qrph': GCashQRPHRow,
    card        : CreditDebitRow,
    jpt         : JPTRow,
}

type BankType = 'allbank' | 'rbgi' | 'icash' | 'gcash-h5' | 'card' | 'jpt' | 'gcash-qrph'

interface Props {
    type        : string
    title?      : string
    bank        : BankType
    source     ?: string
    intent     ?: string
    provider   ?: string
}

const PendingTransactionsTable = ({
    bank,
    source,
    title,
    intent,
    type,
    provider
}: Props) => {
    const {id}      = useParams();
    const {partner} = useLoggedInRoute();
    const {
        getTransactions,
        getPendingTransactionApprovalStatus,
        cancelPendingTransactionsApproval,
        processPendingTransactionApproval,
        approvePendingTransactions,
    } = useApi();

    const [status, setStatus]             = useState<'transaction_selection' | 'approval'>();
    const [approvers, setApprovers]       = useState<{approver1: boolean, approver2: boolean}>();
    const [data, setData]                 = useState<any[]>([]);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [loading, setLoading]           = useState(false);
    const [processing, setProcessing]     = useState(false);
    const [last_key, setLastKey]          = useState<string>('');

    const TableColumns = ({Columns, ...params}: any) => <Columns {...params}/>
    const TableRows    = ({Rows, ...params}: any) => <Rows {...params}/>

    const fetchTransactions = () => {
        getTransactions({id, type, bank, source, intent, provider,
            start  : moment().subtract(1, 'year').format('YYYY-MM-DD'),
            end    : moment().format('YYYY-MM-DD'),
            pending: true
        }).then(res => {
            if (res.status == 401) {
                sessionExpired()
            }
            return res
        })
        .then(res => res.json())
        .then(response => {
            if (last_key) {
                response.transactions = [...transactions, ...response.transactions];
            }
            setLastKey(response.LastEvaluatedKey);
            setData(response.transactions);
        })
        .catch(() => sessionExpired())
    }

    const apply = () => {
        setLoading(true)
        fetchTransactions()
    }

    const sessionExpired = () => Swal.fire({
        title: 'Session Expired',
        text: 'Please login again',
        icon: 'warning',
        allowOutsideClick: false
    }).then((value) => {
        if (value.isConfirmed) {
            sessionStorage.clear()
            window.location.reload()
        }
    })

    const toggle = (index: number) => {
        const t = [...transactions];
        t[index].checked = !t[index].checked
        setTransactions(t)
    }

    const toggleAll = () => {
        const selected = transactions.every((value: any) => value.checked);
        setTransactions(
            transactions.map(
                (transaction) => ({...transaction, checked: !selected})
            )
        )
    }

    const getApprovalStatus = () => {
        getPendingTransactionApprovalStatus()
        .then(response => {
            setStatus(response.status);
            setApprovers(response.approvers);
            setTransactions(
                data.map(
                    (transaction) => ({
                        ...transaction,
                        checked: response.transactions.includes(transaction.payment_id)
                    })
                )
            )
        }).finally(() => {
            setLoading(false)
            setProcessing(false)
        })
    }

    const process = () => {
        if (transactions.every(t => !t.checked)) {
            return Swal.fire({
                icon: 'warning',
                title: 'No Transactions Selected'
            })
        }
        setProcessing(true)
        processPendingTransactionApproval(
            transactions
            .filter(t => t.checked)
            .map(t => t.payment_id)
        )
        .then(updatePendingStatus)
        .finally(getApprovalStatus)
    }

    const approve = (i: 1 | 2) => {
        setProcessing(true)
        approvePendingTransactions(i)
            .then(updatePendingStatus)
            .finally(getApprovalStatus)
    }

    const cancel = () => {
        Swal.fire({
            icon              : 'warning',
            title             : 'Are you sure you want to cancel?',
            confirmButtonText : 'Yes',
            confirmButtonColor: '#1976d2',
            cancelButtonText  : 'No',
            cancelButtonColor : '#c62828',
            showCancelButton  : true,
            allowEscapeKey    : false,
            allowOutsideClick : false,
            allowEnterKey     : false
        }).then(value => {
            if (value.isConfirmed) {
                setProcessing(true)
                cancelPendingTransactionsApproval()
                    .then(updatePendingStatus)
                    .finally(getApprovalStatus)
            }
        })
    }

    const updatePendingStatus = (response: any) => {
        if ('transaction_selection' == response.status) {
            return apply();
        }
        setStatus(response.status);
        setApprovers(response.approvers);
    }
    
    useEffect(apply, [partner?.access_token])
    useEffect(getApprovalStatus, [data])

    return <>
        <Card sx={styles.card}>
            <CardContent>
                <Grid container
                    spacing        = {2}
                    justifyContent = "space-between"
                    alignItems     = "center"
                >
                    <Grid item>
                        <Typography variant="h6" fontWeight="bold" sx={styles.tableTitle}>Pending Transactions {title? `(${title})`: ''}</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant  = "contained"
                            onClick  = {apply}
                            disabled = {loading}
                        >
                            {loading? <CircularProgress size={25}/>: 'Refresh'}
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={styles.tableContainer}>
                    <Table size="small" stickyHeader style={{minWidth: '100%'}}>
                        <TableHead>
                            <TableColumns isAdmin pending
                                Columns  = {Columns[bank]}
                                toggle   = {toggleAll}
                                selected = {transactions.every((value: any) => value.checked)}
                                disabled = {status != 'transaction_selection'}
                            />
                        </TableHead>
                        <TableBody>
                            {transactions?.map((item: any, i) => 
                                <TableRows isAdmin pending
                                    Rows          = {Rows[bank]}
                                    item          = {item}
                                    amountFormat  = {amountFormat}
                                    computeAmount = {computeAmount}
                                    toggle        = {() => toggle(i)}
                                    disabled      = {status != 'transaction_selection'}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {status && <Box>
                    <Typography display="inline-block">Process with Payments?</Typography>
                    {
                        'transaction_selection' == status? <LoaderButton sx={{margin: '1rem'}} loading={processing} disabled={processing} onClick={process}>Process</LoaderButton>:
                        'approval'              == status? <>
                            <LoaderButton sx={{margin: '1rem'}} loading={processing} disabled={approvers?.approver1 || processing} onClick={() => approve(1)}>Approver 1</LoaderButton>
                            <LoaderButton sx={{margin: '1rem'}} loading={processing} disabled={approvers?.approver2 || processing} onClick={() => approve(2)}>Approver 2</LoaderButton>
                            <LoaderButton sx={{margin: '1rem'}} loading={processing} disabled={processing} color="error" onClick={cancel}>Cancel</LoaderButton>
                        </>:<></>
                    }
                </Box>}
            </CardContent>
        </Card>
    </>
}

const amountFormat = (amount: number) => (+(amount || 0)).toLocaleString(undefined, {'minimumFractionDigits':2,'maximumFractionDigits':2})
const computeAmount = (amount: number, fee_percentage: number) => amount - amount * fee_percentage/100

export default PendingTransactionsTable;