import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const JPTColumns = ({isAdmin, hideAmount, pending, toggle, selected, disabled}: any) => {
    return isAdmin? (
        pending?
        <TableRow>
            <TableCell>client</TableCell>
            <TableCell>payment_id</TableCell>
            <TableCell>reference_number</TableCell>
            <TableCell>tranID</TableCell>
            <TableCell>tranDate</TableCell>
            <TableCell>intent</TableCell>
            <TableCell>account_name</TableCell>
            <TableCell>account_number</TableCell>
            <TableCell>bank_wallet</TableCell>
            <TableCell>currency</TableCell>
            <TableCell>tranAmount</TableCell>
            {!hideAmount && <TableCell>amount</TableCell>}
            <TableCell>status</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Payment ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Amount</TableCell>
            {!hideAmount && <TableCell>Amount</TableCell>}
            <TableCell>Status</TableCell>
        </TableRow>
    ):
        <TableRow>
            <TableCell>Reference No.</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Intent</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Bank/Wallet</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Amount</TableCell>
            {!hideAmount && <TableCell>Amount</TableCell>}
            <TableCell>Status</TableCell>
        </TableRow>
}

export default JPTColumns;