import { Grid } from "@mui/material"
import AccountsTotals from "app/cards/AccountsTotals"
import BarGraph from "app/cards/BarGraph"
import TerminalTable from "app/cards/TerminalTable"
import TransactionsTable from "app/cards/TransactionsTable"
import useLoggedInRoute from "app/hooks/useLoggedInRoute"

const CashIn = () => {

    const {partner, type} = useLoggedInRoute();

    const cards: any[] = [
        BarGraph,
        () => <AccountsTotals title="QRPH" method="qrph"/>,
        // () => <AccountsTotals title="GCash-H5" method="gcash"/>,
        () => <AccountsTotals title="Credit/Debit Cards" method="card"/>,
        partner?.profile?.p2m?.enabled && (() => (
            <TransactionsTable showBalance
                bank    = "allbank"
                source  = "p2m"
                intent  = "cash_in"
                title   = "QRPH CASH-IN (AllBank - P2M)"
                type    = {type || ''}
            />
        )),
        partner?.profile?.banks?.['gcash-qrph'] && (() => (
            <TransactionsTable showTotal
                bank    = "gcash-qrph"
                intent  = "cash_in"
                title   = "GCASH QRPH"
                type    = {type || ''}
            />
        )),
        partner?.profile?.jpt?.cash_out && (() => (
            <TransactionsTable showBalance
                bank    = "jpt"
                intent  = "cash_out"
                title   = "QRPH CASH-OUT (AllBank - OPC)"
                type    = {type || ''}
            />
        )),
        // partner?.profile?.banks?.gcash && (() => (
        //     <TransactionsTable showBalance
        //         bank    = "gcash-h5"
        //         intent  = "cash_in"
        //         title   = "GCASH CASH-IN (GCASH - H5)"
        //         type    = {type || ''}
        //     />
        // )),
        // partner?.profile?.banks?.gcash && partner?.profile?.cash_out?.gcash && (() => (
        //     <TransactionsTable showBalance
        //         bank    = "gcash-h5"
        //         intent  = "cash_out"
        //         title   = "GCASH CASH-OUT (GCASH - H5)"
        //         type    = {type || ''}
        //     />
        // )),
        partner?.profile?.banks?.card && (() => (
            <TransactionsTable showBalance
                bank    = "card"
                title   = "Credit/Debit Cards"
                type    = {type || ''}
            />
        )),
        () => <TerminalTable />,
    ]

    return <>{
        cards.map((Card, i, _) => 
            Card && <Grid key={i} item xs={12}>
                <Card />
            </Grid>
        )
    }</>
}

export default CashIn;