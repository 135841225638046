import { Grid } from "@mui/material";
import BarGraph from "app/cards/BarGraph";
import Providers from "app/cards/Providers";
import TerminalTable from "app/cards/TerminalTable";
import TransactionsTable from "app/cards/TransactionsTable";
import useLoggedInRoute from "app/hooks/useLoggedInRoute";
import usePartner from "app/hooks/usePartner";

const Client = () => {
    const {partner, type} = useLoggedInRoute();
    const {client, account} = usePartner();

    const cards: any[] = [
        () => <BarGraph
            client={client}
            account={account}
        />,
        'admin' == type && (() => <Providers
            client={client}
            account={account}
        />),
        ('admin' == type || partner?.profile?.p2m?.enabled) && (() => (
            <TransactionsTable
                showBalance = {'admin' == type}
                showTotal   = {'partner' == type}
                bank        = "allbank"
                source      = "p2m"
                intent      = "cash_in"
                title       = "QRPH CASH-IN (AllBank - P2M)"
                type        = {type || ''}
                client      = {client}
                account     = {account}
            />
        )),
        ('admin' == type || partner?.profile?.banks?.['gcash-qrph']) && (() => (
            <TransactionsTable showTotal
                bank    = "gcash-qrph"
                intent  = "cash_in"
                title   = "GCASH QRPH"
                type    = {type || ''}
            />
        )),
        ('admin' == type || partner?.profile?.jpt?.cash_out) && (() => (
            <TransactionsTable showBalance
                bank    = "jpt"
                intent  = "cash_out"
                title   = "QRPH CASH-OUT (AllBank - OPC)"
                type    = {type || ''}
                client  = {client}
                account = {account}
            />
        )),
        ('admin' == type || partner?.profile?.banks?.card) && (() => (
            <TransactionsTable showTotal
                bank     = "jpt"
                title    = "Credit/Debit Cards"
                intent   = "cash_in"
                category = "mastercard_visa"
                type     = {type || ''}
                client   = {client}
                account  = {account}
            />
        )),
        'admin' == type && (() => <TerminalTable
            client   = {client}
            account  = {account}
        />),
    ]

    return <>{
        cards.map((Card, i, _) => 
            Card && <Grid key={i} item xs={12}>
                <Card />
            </Grid>
        )
    }</>
}

export default Client;