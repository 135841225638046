import { TableRow } from "@mui/material";
import TableCell from "../TableCell";

const GCashQRPHColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>TID</TableCell>
            <TableCell>Reference No.</TableCell>
            <TableCell>QRPH Invoice No.</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Paid Date</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>TID</TableCell>
            <TableCell>Reference No.</TableCell>
            <TableCell>QRPH Invoice No.</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Paid Date</TableCell>
        </TableRow>
}

export default GCashQRPHColumns;