import { TableCell, TableRow } from "@mui/material";

const GCashQRPHColumns = ({isAdmin}: any) => {
    return isAdmin?
        <TableRow>
            <TableCell>transaction_id</TableCell>
            <TableCell>client_ref</TableCell>
            <TableCell>tid</TableCell>
            <TableCell>reference_id</TableCell>
            <TableCell>instapay_ref</TableCell>
            <TableCell>tran_amount</TableCell>
            <TableCell>amount</TableCell>
            <TableCell>currency</TableCell>
            <TableCell>status</TableCell>
            <TableCell>created_at</TableCell>
            <TableCell>updated_at</TableCell>
        </TableRow>:
        <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Client Reference</TableCell>
            <TableCell>TID</TableCell>
            <TableCell>Reference No.</TableCell>
            <TableCell>QRPH Invoice No.</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Paid Date</TableCell>
        </TableRow>
}

export default GCashQRPHColumns;